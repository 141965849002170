import { useSelector } from "react-redux";

const useRoamingCCTerminalState = () => {
  const currentRoamingCCTerminals = useSelector((state) => state.cashier.roamingCCTerminals);
  return {
    roamingCCTerminals: currentRoamingCCTerminals,
  };
};

export default useRoamingCCTerminalState;
