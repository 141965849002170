import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/* Services */
import FeatureFlagService from "../../../services/FeatureFlagService";
import apiClient from "../../../auth/apiClient";

const featureFlagService = new FeatureFlagService(apiClient);

export const fetchFlags = createAsyncThunk(
  "features/fetchFlagsByEntityId",
  async (entityID, thunkAPI) => {
    try {
      const features = await featureFlagService.getFeatureFlags(entityID);

      return features.data;
    } catch (e) {
      return console.error(e);
    }
  }
);

const featureFlagSlice = createSlice({
  name: "featureFlag",
  initialState: {flags: undefined, isLoading: false},
  reducers: {
    addFlag: (state, action) => {
      state.flags = [...state.flags, action.payload];
    },
    removeFlag: (state, action) => {
      const flags = [...state.flags];
      flags.splice(flags.findIndex(flag => flag === action.payload), 1);
      state.flags = flags;
    },
    clearFlags: (state, action) => {
      state.flags = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFlags.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(fetchFlags.fulfilled, (state, action) => {
      state.flags = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchFlags.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  addFlag,
  removeFlag,
  clearFlags
} = featureFlagSlice.actions;

export default featureFlagSlice.reducer;
