import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import PeripheralService from "../../services/PeripheralService";
import apiClient from "../../auth/apiClient";

const service = new PeripheralService(apiClient);

export const fetchPeripheralsList = createAsyncThunk(
  "peripherals/fetchPeripheralsByEntityID",
  async (entityID, thunkAPI) => {
    try {
      const result = await service.getPeripherals(entityID);
      return result.data ?? [];
    } catch (e) {
      return console.error(e);
    }
  }
);

const peripheralsSlice = createSlice({
  name: "peripherals",
  initialState: {},
  reducers: {
    addPeripheral: (state, action) => {
      state.peripherals = [...state.peripherals, action.payload];
    },
    updatePeripheral: (state, action) => {
      state.peripherals = [...state.peripherals.filter(peripheral => peripheral.peripheralID !== action.payload.peripheralID), action.payload];
    },
    removePeripheral: (state, action) => {
      state.peripherals = state.peripherals.filter(peripheral => peripheral.peripheralID !== action.payload.peripheralID);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPeripheralsList.fulfilled, (state, action) => {
      state.peripherals = action.payload;
    });
  },
});

export const {
  addPeripheral,
  updatePeripheral,
  removePeripheral,
} = peripheralsSlice.actions;

export default peripheralsSlice.reducer;