import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CashierService from "../../../services/CashierService";
import apiClient from "../../../auth/apiClient";

const cashierService = new CashierService(apiClient);

export const fetchRoamingCCTerminals = createAsyncThunk(
  "cashier/fetchRoamingCCTerminals",
  async (entityID, thunkAPI) => {
    try {
      const roamingCCTerminals = await cashierService.getRoamingCCTerminals(entityID);
      return roamingCCTerminals.data.collection ?? [];
    } catch (e) {
      return console.error(e);
    }
  }
);

const cashierSlice = createSlice({
  name: "cashier",
  initialState: {},
  reducers: {
    addRoamingCCTerminals: (state, action) => {
      state.roamingCCTerminals = [...state.roamingCCTerminals, action.payload];
    },
    editRoamingCCTerminals: (state, action) => {
      state.roamingCCTerminals = [...state.roamingCCTerminals
        .filter(roamingCCTs => roamingCCTs.roamingCCTerminalID !== action.payload.roamingCCTerminalID), action.payload]
    },
    removeRoamingCCTerminals: (state, action) => {
      state.roamingCCTerminals = state.roamingCCTerminals
        .filter(roamingCCTs => roamingCCTs.roamingCCTerminalID !== action.payload);      
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRoamingCCTerminals.fulfilled, (state, action) => {
      state.roamingCCTerminals = action.payload;
    });
  },
});

export const {
  addRoamingCCTerminals,
  editRoamingCCTerminals,
  removeRoamingCCTerminals,
} = cashierSlice.actions;

export default cashierSlice.reducer;
