import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useEntitySettings from "../../../hooks/useEntitySettings";
import { getOfferObjectInCorrectSettingOrder } from "../../../utils/sorting/poe";

/**
 * @typedef {Object} PayOnEntry
 * PayOnEntry Offers
 * @property {Object} offers
 * The entire collection of offers available for this entity to select from
 * @property {Object} selectedOffers
 * The collection of offers that are actually in use by the entity
 *
 * @typedef {Object} Options
 * The configuration options for the usePoeState hook
 * @property {string} entityID
 * The entityID to return selected offers for
 *
 * @param {Options}
 *
 * @returns {PayOnEntry}
 */
const usePoeState = (Options) => {
  const currentOffers = useSelector((state) => state.payOnEntry.offers);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const { getSettingValue } = useEntitySettings();

  useEffect(() => {
    if (!Options?.entityID) return;
    try {
      const offers = JSON.parse(getSettingValue(Options.entityID, "poeoffers"));
      setSelectedOffers(
        getOfferObjectInCorrectSettingOrder(currentOffers, offers)
      );
    } catch (err) {
      console.error(err);
    }
  }, [Options?.entityID, currentOffers]);

  return {
    offers: currentOffers,
    selectedOffers,
  };
};

export default usePoeState;
