/**
 *
 * @param {Object[]} fullOfferList This will be the complete set of offers as an object array, where the value property is the offer name
 * @param {string[]} selectedOffersForEntity This will be the selected offers for the entity in the correct sort order, has only ids
 * @returns {Object[]} Returns the selected offers object, in the correct order with properties from the full offer object, namely the offer name
 */

export const getOfferObjectInCorrectSettingOrder = (
  fullOfferList,
  selectedOffersForEntity
) => {
  //creates a way to know original sort order from settings
  const offerIDsObjectArray = selectedOffersForEntity.map((id, i) => {
    return { id: id, index: i };
  });
  
  //filter offers from full list of offers object array
  //creates new object with sortable index prop
  //sorts on new prop
  const foundOffers = fullOfferList
    .filter((offer) => selectedOffersForEntity.find((x) => x === offer.offerID))
    .map((offer) => {
      let target = {};
      let newOffer = Object.assign(target, offer);
      newOffer.originalSettingsIndex = offerIDsObjectArray.findIndex(
        (x) => x.id === offer.offerID
      );
      return newOffer;
    })
    .sort((a, b) => a.originalSettingsIndex - b.originalSettingsIndex); //sort acs

  return foundOffers;
};
