import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CashierShiftsService from "../../../services/CashierShiftsService";
import CashierService from "../../../services/CashierService";
import apiClient from "../../../auth/apiClient";
import _ from "lodash";
import { PAYMENT_TYPES, CASHIER_DEVICE } from "../../../constants";


const cashierShiftsService = new CashierShiftsService(apiClient);
const cashierService = new CashierService(apiClient);

export const fetchCashierShift = createAsyncThunk(
  "cashiershifts/getbyuserid/",
  async (userID, thunkAPI) => {
    try {
      const currentCashierShift = await cashierShiftsService.getCashierShiftByUserId(
        userID
      );
      return (
        currentCashierShift?.data ?? {
          shiftID: null,
          startCash: null,
          deviceID: null,
          roamingCCTerminalID: null,
          entityID: null,
          endShiftAfterTransaction: null
        }
      );
    } catch (e) {
      return console.error(e);
    }
  }
);

export const hydrateTransaction = createAsyncThunk(
  "cashier/transaction/hydrate",
  async (cashierID, thunkAPI) => {
    const response = await cashierService.getTransactionStatus(cashierID);
    console.log("hydration response: ", response.data);
    return response.data;
  }
);

export const defaultTransactionState = {
  validations: [],
  amountPaid: {},
  rateReceipt: {},
  started: false,
  creditDetails: {},
  hydrated: undefined,
  hydratedPage: "start",
};

const shiftSessionSlice = createSlice({
  name: "shiftSession",
  initialState: {
    shiftID: null,
    startCash: null,
    cashieredDeviceID: null,
    ccterminalId: null,
    entityID: null,
    endShiftAfterTransaction: false,
    transaction: defaultTransactionState,
    cashierDeviceTypeID: null,
    valetActivity: null
  },
  reducers: {
    initializeStyle: (state, action) => {
      const { small, toastLocation } = action.payload;
      state.smallScreen = small;
      state.toastLocation = toastLocation;
    },
    poeOfferSelected: (state, action) => {
      const { offer, parkingTransactionID } = action.payload;
      state.transaction.selectedOffer = offer;
      state.transaction.parkingTransactionID = parkingTransactionID;
    },
    feeCalculated: (state, action) => {
      const {
        rateValidUntil,
        totalCost,
        initialTotal,
        rateReceipt,
      } = action.payload;
      state.transaction.rateReceipt = rateReceipt;
      state.transaction.rateValidUntil = rateValidUntil;
      state.transaction.totalCost = totalCost;
      state.transaction.initialTotal = initialTotal;
    },
    credentialValidated: (state, action) => {
      state.transaction.credential = action.payload;
      state.transaction.parkingTransactionID =
        action.payload.parkingTransactionID;
      state.transaction.validations = action.payload.validations;
    },
    clearTransaction: (state, action) => {
      state.transaction = { ...defaultTransactionState, hydrated: true };
    },
    valetTicketNumberValidated: (state, action) => {
      state.valetActivity = action.payload;
    },
    clearValetActivity: (state, action) => {
      state.valetActivity = null;
    },
    updateTransactionStatus: (state, action) => {
      state.transaction.status = action.payload.status;
      state.transaction.paymentComplete = action.payload.status === 3;
    },
    updateCreditDisplay: (state, action) => {
      state.transaction.creditDisplayLines = action.payload.creditDisplayLines;
    },
    addPayment: (state, action) => {
      const { total, paymentType } = action.payload;
      state.transaction.amountPaid.total = total;
      state.transaction.amountPaid.paymentType = paymentType;
    },
    setValidationNavigation: (state, action) => {
      const { enableNext, enableBack } = action.payload;
      state.validationNavigation = {
        enableNext,
        enableBack
      }
    },
    setValidationCanBack: (state, action) => {
      const { showNext } = action.payload;
      state.validationShowNext = showNext;
    },
    addClaimedValidation: (state, action) => {
      const {
        validationsInventoryID,
        validationOfferName,
        validationTypeID,
        validationKindID,
        validationFormatID,
        validationAmount,
      } = action.payload;
      const validation = {
        validationsInventoryID,
        validationOfferName,
        validationTypeID,
        validationKindID,
        validationFormatID,
        validationAmount,
      };
      state.transaction.validations.push(validation);
      if (state.transaction.scannedCredential) {
        state.transaction.scannedCredential.validations.push(validation);
      }
    },
    unclaimedValidation: (state, action) => {
      state.transaction.validations = state.transaction.validations.filter(
        (x) => x.validationsInventoryID !== action.payload
      );
    },
    startTransaction: (state, action) => {
      state.transaction.started = true;
      state.transaction.reference = action.payload.reference;
    },
    processingCashPayment: (state, action) => {
      state.transaction.processingCash = true;
    },
    endProcessingCashPayment: (state, action) => {
      state.transaction.processingCash = false;
    },
    createCashierShift: (state, action) => {
      console.log("creating shift: ", action.payload);
      state.shiftID = action.payload.shiftID;
      state.startCash = action.payload.startCash;
      if (state.cashieredDeviceID !== CASHIER_DEVICE.ValetHandheldCashier) {
        state.cashieredDeviceID = action.payload.deviceID
          ? action.payload.deviceID
          : null;
      }
      state.entityID = action.payload.entityID;
      state.ccterminalId = action.payload.roamingCCTerminalID;
    },
    setCashieredDevice: (state, action) => {
      console.log("setting cashier shift: ", action.payload);
      state.cashieredDeviceID = action.payload;
    },

    setCCTerminal: (state, action) => {
      state.ccterminalId = action.payload;
    },
    addAdjustedFee: (state, action) => {
      console.log("setting adjusted Fee: ", action.payload);
      state.transaction.adjustedFee = action.payload;
    },
    endCashierShift: (state, action) => {
      console.log("ending shift");
      state.shiftID = 0;
      state.startCash = 0;
      state.ccterminalId = null;
      state.entityID = null;
      state.endShiftAfterTransaction = false;
      state.valetActivity = null;
    },

    cashierShiftSetEndWhenAfterTransaction: (state, action) => {
      console.log("button pressed to end cashier shift with transaction in progress");
      state.endShiftAfterTransaction = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCashierShift.fulfilled, (state, action) => {
      console.log("fufilled shift: ", action.payload);
      if (action.payload) {
        state.shiftID = action.payload.shiftID;
        state.startCash = action.payload.startCash;
        if (action.payload.deviceID === "00000000-0000-0000-0000-000000000000") {
          switch (action.payload.cashierDeviceTypeID) {
            case 1:
              state.cashieredDeviceID = CASHIER_DEVICE.RoamingCashierdefault;
              break;
            case 2:
              state.cashieredDeviceID = CASHIER_DEVICE.ValetHandheldCashier;
              break;
          }
        } else {
          state.cashieredDeviceID = action.payload.deviceID;
        }
        state.ccterminalId = action.payload.roamingCCTerminalID;
        state.entityID = action.payload.entityID;
      } else {
        state.shiftID = 0;
        state.startCash = 0;
        if (state.cashieredDeviceID !== CASHIER_DEVICE.ValetHandheldCashier) {
          state.cashieredDeviceID = null;
        }
        state.ccterminalId = null;
        state.entityID = null;
        state.endShiftAfterTransaction = false;
      }
    });
    builder.addCase(hydrateTransaction.fulfilled, (state, action) => {
      state.transaction.hydrated = true;
      if (_.isEmpty(action.payload)) return;

      const {
        paymentDetails,
        status,
        validations,
        transactionReference,
        approvedPayment,
        gatewayAmount,
        rateDetails,
        displayLines,
      } = action.payload;
      const selectedOffer = paymentDetails?.selectedOffer;
      const parkingTransactionID = paymentDetails?.parkingTransactionID;
      const scannedCredential = paymentDetails?.scannedCredential;

      state.transaction.started = true;
      state.transaction.reference = transactionReference;
      state.transaction.selectedOffer = selectedOffer;
      state.transaction.parkingTransactionID = parkingTransactionID;
      state.transaction.credential = scannedCredential;

      state.transaction.validations =
        validations?.map((v) => {
          return {
            validationsInventoryID: v.inventoryID,
            validationOfferName: v.offerName,
            validationAmount: v.amount,
            validationKindID: v.kindID,
            validationTypeID: v.typeID,
            validationFormatID: v.formatID,
          };
        }) ?? [];

      state.transaction.rateReceipt.entryTime = rateDetails.entryFacilityTime;
      state.transaction.rateReceipt.exitTime = rateDetails.exitFacilityTime;
      state.transaction.totalCost = gatewayAmount;
      state.transaction.creditDisplayLines = {
        displayLine1: displayLines[0]?.trim() ?? "",
        displayLine2: displayLines[1]?.trim() ?? "",
      };

      if (approvedPayment) {
        state.transaction.amountPaid = {
          total: approvedPayment.totalPaid,
          paymentType: approvedPayment.isCreditCard
            ? PAYMENT_TYPES.CreditCard
            : PAYMENT_TYPES.Cash,
        };
        state.transaction.totalCost = approvedPayment.amountDue;
        state.transaction.paymentComplete = status === 3; // payment completed
      }

      state.transaction.status = status;

      switch (status) {
        case 15: // pending
        case 5: // cancelled
        case 6: // declined
        case 4: // failed
          state.transaction.hydratedPage = "payment";
          break;
        case 1: // InProgress
        case 2: // AwaitingPaymentCompleted
          if (gatewayAmount <= 0) {
            state.transaction.hydratedPage = "payCash";
          } else {
            state.transaction.hydratedPage = "payCredit";
          }
          break;
        case 3: // completed
          state.transaction.hydratedPage = "summary";
          break;
      }
    });
    builder.addCase(hydrateTransaction.rejected, (state, action) => {
      console.warn("Failed to hydrate transaction for cashier");
      state.transaction.hydrated = false;
    });
  },
});

export const {
  createCashierShift,
  setCashieredDevice,
  setCCTerminal,
  endCashierShift,
  poeOfferSelected,
  startTransaction,
  processingCashPayment,
  updateTransactionStatus,
  updateCreditDisplay,
  endProcessingCashPayment,
  feeCalculated,
  setValidationNavigation,
  addClaimedValidation,
  addAdjustedFee,
  unclaimedValidation,
  credentialValidated,
  clearTransaction,
  valetTicketNumberValidated,
  clearValetActivity,
  initializeStyle,
  cashierShiftSetEndWhenAfterTransaction,
  addPayment,
} = shiftSessionSlice.actions;
export default shiftSessionSlice.reducer;
