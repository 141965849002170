import React from "react";
import { createSlice }  from '@reduxjs/toolkit';

import _ from "lodash";
const initialState = {
    CredentialType: "", 
    ActivityType  : ""
}

const activitySlice = createSlice({
  name: 'activity',
  initialState: initialState,
  // reducers actions
  reducers: {
    setCredentialType    : (state, action) => {state.CredentialType = action.payload},
    setActivityType      : (state, action) => {state.ActivityType = action.payload},
    clearActivityFilters : (state, action) => {
      state.CredentialType = initialState.CredentialType; 
      state.ActivityType = initialState.ActivityType;
    }
  }
})
//actions
export const {setCredentialType, setActivityType, clearActivityFilters} = activitySlice.actions;

export default activitySlice.reducer;
