import { useSelector } from "react-redux";

const useCashierShiftState = () => {
  const currentShiftId = useSelector((state) => state.shiftSession?.shiftID);
  return {
    currentShiftID: currentShiftId,
  };
};

export default useCashierShiftState;
