import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/* Services */
import PayOnEntryService from "../../../services/PayOnEntryService";
import apiClient from "../../../auth/apiClient";

const payOnEntryService = new PayOnEntryService(apiClient);

export const fetchOffers = createAsyncThunk(
  "offers/fetchOffersByEntityID",
  async (entityID, thunkAPI) => {
    try {
      const offers = await payOnEntryService.getPOEOffers(entityID);
      return offers.data.collection ?? [];
    } catch (e) {
      return console.error(e);
    }
  }
);


const payOnEntrySlice = createSlice({
  name: "payOnEntry",
  initialState: {},
  reducers: {
    createOffer: (state, action) => {
      state.offers = [...state.offers, action.payload];
    },
    updateOffer: (state, action) => {
      state.offers = [...state.offers.filter(offer => offer.offerID !== action.payload.offerID), action.payload]
    },
    removeOffer: (state, action) => {
      state.offers = state.offers.filter(offer => offer.offerID !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOffers.fulfilled, (state, action) => {
      state.offers = action.payload;
    });
  },
});

export const {
  createOffer,
  updateOffer,
  removeOffer,
} = payOnEntrySlice.actions;

export default payOnEntrySlice.reducer;
