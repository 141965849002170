import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CountryService from '../../services/CountryService';
import apiClient from '../../auth/apiClient';

const countryService = new CountryService(apiClient);

const initialState = {
  countries: [],
  subdivisions: [],
  loading: false
}

export const fetchCountries = createAsyncThunk(
  'countries/getCountries',
  async (thunkAPI) => {
    try {
      const response = await countryService.getCountries();

      return response.data
    } catch (error) {
      return error
    }
  }
)

export const fetchSubdivisions = createAsyncThunk(
  'countries/getSubdivisions',
  async (countryId, thunkAPI) => {
    try {
      const response = await countryService.getSubdivisions(countryId);

      return response.data
    } catch (error) {
      return error
    }
  }
)

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: initialState,
  reducers: {
    loading(state, action) {
      state.loading = action.payload
    }
  },
  extraReducers: {
    [fetchCountries.pending] : (state) => {
      state.loading = true;
    },
    [fetchCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload
      state.loading = false;
    },
    [fetchCountries.rejected] : (state) => {
      state.loading = false
    },
    [fetchSubdivisions.pending]: (state) => {
      state.loading = true;
    },
    [fetchSubdivisions.fulfilled]: (state, { payload }) => {
      state.subdivisions = payload;
      state.loading = false;
    },
    [fetchSubdivisions.rejected]: (state) => {
      state.loading = false;
    }
  }
})

export default countriesSlice.reducer; 