// src/features/cart/cartSlice.js
import { createSlice, createEntityAdapter,current } from "@reduxjs/toolkit";
import apiClient from "../../auth/apiClient";
import CallCenterService from "../../services/CallCenterService";

import _ from "lodash";

export const queueAdapter = createEntityAdapter({
  selectId: (qi) => qi.callID,
  initialState: [],
});
const initialState = queueAdapter.getInitialState();

const callCenterService = new CallCenterService(apiClient);

const callcenterSlice = createSlice({
  name: "callcenter",
  initialState: { Queue: [] },
  // reducers actions
  reducers: {
    loadQueue: (state, action) => {
      state.Queue = action.payload?.map((item) => {
        item.viewing = item.details?.viewing ?? [];
        return item;
      });
    },
    addToQueue: (state, action) => {
      //we want to filter out existing calls for the same device without viewers. The backend will end existing calls via a db trigger.
      let filteredQueue = _.filter(state.Queue,(obj)=>{
        return !(obj.deviceID ===action.payload.deviceID)
      });
      state.Queue = [action.payload, ...filteredQueue];
    },
    removeFromQueue: (state, action) => {
      state.Queue = state.Queue.filter(
        (qi, i) => qi.callID !== action.payload.callID
      );
    },
    setConnection: (state, action) => {
      const { callID, user, state: itemState } = action.payload;
      let qi = _.find(state.Queue, { callID });
      if (!(qi === undefined)) qi.connected = { user, state: itemState };
    },
    removeViewer: (state, action) => {
      const { callID, viewer } = action.payload;
      let qi = _.find(state.Queue, { callID });
      if (qi === undefined) return;
      qi.viewing = qi.viewing?.filter((e, i) => e !== viewer);
      //action.payload will be  {callID: x, viewer: emailaddress}   //emails are unique in the system, so good enough w/o security risk
    },
    addViewer: (state, action) => {
      const { callID, viewer } = action.payload;
      let qi = _.find(state.Queue, { callID });
      if (qi === undefined) return;
      qi.viewing = [...(qi.viewing ?? []), viewer];
    },
    reset: () => initialState,
  },
});

//actions
export const {
  loadQueue,
  addToQueue,
  removeFromQueue,
  removeViewer,
  addViewer,
  setConnection,
  reset,
} = callcenterSlice.actions;
export default callcenterSlice.reducer;

export const fetchQueue = (entityID) => async (dispatch) => {
  try {
    const response = await callCenterService.fetchQueue(entityID);
    dispatch(loadQueue(response.data ?? []));
  } catch (err) {
    console.error(err);
  }
};

export const fetchQueueByUserID = (userID) => async (dispatch) => {
  try {
    const response = await callCenterService.fetchQueueByUserID(userID);
    dispatch(loadQueue(response.data ?? []));
  } catch (err) {
    console.error(err);
  }
};

//4aa9fbcb-e0df-4ea3-9976-de8590a809fb    --AMI
//e3e2b062-258a-4705-971b-583cc3096cfe    --bobs belcher lot
//5d6c2ba6-b699-4c1b-88e0-ad9087452309    --callibrity terminal
