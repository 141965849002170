import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

/* Services */
import apiClient from "../../auth/apiClient";

import EntityService from "../../services/EntityService";
import { ENTITY_TYPE } from "../../constants";
const entityService = new EntityService(apiClient);

export const loadScope = createAsyncThunk(
  "entityScope/load",
  async (contextEntity, thunkAPI) => {
    await thunkAPI.dispatch(
      setFacilityGroup(null)
    );
    await thunkAPI.dispatch(
      setScope([contextEntity])
    );
    await thunkAPI.dispatch(
      setSelected([contextEntity])
    );
    const scope = await entityService.getScope(contextEntity.id);
    return scope.data;
  }
);


const entityScopeSlice = createSlice({
  name: "entityScope",
  initialState: { available: [], selected: [], facilityGroupId: null },
  reducers: {
    setFacilityGroup: (state, action) => {
      state.facilityGroupId = action.payload;
    },
    setScope: (state, action) => {
      state.available = [...action.payload];
    },
    clearScope: (state, action) => {
      state.available = [action.payload];
    },
    setSelected: (state, action) => {
      state.selected = [...action.payload]
    },
    clearSelected: (state, action) => {
      state.selected = [action.payload]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadScope.fulfilled, (state, action) => {
      state.available = action.payload.scopeEntities;
      state.facilityGroupId = action.payload.facilityGroupId ?? null;
    });
  }
});

export const {
  setFacilityGroup,
  setScope,
  clearScope,
  setSelected,
  clearSelected,
} = entityScopeSlice.actions;

/**
 * Searches down the tree provided
 * @param {Array<{ id, children: [] }>} entityList - Array of entities to start the search from
 * @param targetId - The entity ID searching for
 * @returns The entity in full from the list if found, null otherwise
 */
export function FindEntityInScope(entityList, targetId) {
  if (entityList && entityList.length < 0) {
    return null;
  }

  for (let entity of entityList) {
    if (entity.id === targetId) {
      return entity;
    }

    if (entity.children) {
      let found = FindEntityInScope(entity.children, targetId);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

/**
 * Returns the entire entity node of the facility if found. Expects
 * `entityScope.available` as the entity list, though can work with
 * any entity list where each node is an object with at least `id`,
 * `entityType`, and `children` as object keys.
 * @param {*} entityList 
 * @param {*} entityId
 */
export function FindFacilityOfEntity(entityList, entityId) {
  return FindFacilityOfEntityInternal(entityList, entityId, null);
}

function FindFacilityOfEntityInternal(entityList, entityId, currentParent) {
  if (!entityList || entityList.length <= 0) {
    return null;
  }

  for (let entity of entityList) {
    if (entity.entityType === ENTITY_TYPE.Facility) currentParent = entity;
    if (entity.id === entityId) {
      return currentParent;
    }

    if (entity.children) {
      let foundParent = FindFacilityOfEntityInternal(entity.children, entityId, currentParent);
      if (foundParent) {
        return foundParent;
      }
    }
  }
  return null;
}

export default entityScopeSlice.reducer;